.container {
  display: flex;
  justify-content: center;
  width: 65%;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 100%;
  }
}